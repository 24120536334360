import React from 'react';

const WebGraphic = () => (
  <svg viewBox="0 0 565 371" version="1.1">
    <defs>
      <path d="M17.3204011,40.4375312 L15.4231319,40.4375312 C15.6127253,38.7001562 15.7101923,36.9384375 15.7101923,35.1575 C15.7101923,21.458375 10.1385495,9.31725 1.55076923,1.763 C1.12218132,1.3863125 0.70560989,1.00065625 0.29971978,0.60475 L17.3204011,0.60475 L17.3204011,40.4375312 L17.3204011,40.4375312 Z" id="path-1" />
    </defs>
    <g id="Offer" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(-720.000000, -192.000000)">
        <g id="339675-PB132B-350" transform="translate(720.000000, 192.000000)">
          <path d="M376.252469,0 C354.567143,0 334.437217,4.49143151 317.788242,12.1721677 C305.158029,17.9993794 291.058827,19.6600442 277.471643,16.7127849 C182.554814,-3.87272711 77.3163688,42.8964068 77.3163688,115.433673 C77.3163688,125.246221 79.3386473,134.739062 83.1149429,143.734869 C52.2442422,156.472672 31,183.088611 31,213.865918 C31,257.017314 72.7559244,292 124.2647,292 C173.865981,292 214.416019,259.560733 217.352708,218.625283 C221.773863,218.885449 226.244027,219.023946 230.76449,219.023946 C291.31935,219.023946 343.675471,195.342323 368.632812,160.95116 C377.57701,148.624963 390.946231,140.168905 405.846348,137.180226 C448.725617,128.581789 480,101.776873 480,70.0378556 C480,31.3571815 433.55079,0 376.252469,0" id="Fill-4" fill="#DEF0EE" />
          <polygon id="Fill-5" fill="#AD99BD" points="0 285 116 285 116 283 0 283" />
          <polygon id="Fill-6" fill="#EC989D" points="472 262 562 262 562 260 472 260" />
          <path d="M499,71.686829 C499,58.8255745 493.479252,45.5332816 484.682654,40.8514018 C478.246936,37.4263938 472.722322,32.4351501 469.418121,25.9152086 C463.421847,14.0894818 455.787647,7 447.469153,7 C432.51004,7 419.754587,29.9110512 414.786687,62.1030198 C413.494131,70.4713783 409.611308,78.3193842 403.395956,84.0419693 C394.665081,92.0802534 389,105.00364 389,119.581281 C389,141.065893 401.718081,160.047117 419.040652,163.266313 C425.177394,164.407983 430.713606,167.535276 435.355468,171.726575 C439.128752,175.134756 443.210033,177 447.469153,177 C462.932145,177 476.040699,152.522713 480.628436,118.616946 C481.665831,110.955335 484.950702,103.798544 490.13639,98.0824311 C495.506361,92.16439 499,82.5469262 499,71.686829" id="Fill-7" fill="#FADEDA" />
          <polygon id="Fill-8" fill="#EFACB0" points="443 232 445 232 445 59 443 59" />
          <polyline id="Fill-9" fill="#EFACB0" points="414 125.295929 445.704016 165 464 144.696681 462.386299 143.304002 445.804222 161.705437 415.695687 124 414 125.295929" />
          <polyline id="Fill-10" fill="#EFACB0" points="471.438378 74 444.699395 104.128381 435.278959 96.8107951 434 98.4834591 444.961778 107 473 75.4070807 471.438378 74" />
          <path d="M557.294705,138.301975 C555.665983,137.447244 553.85916,137.037388 551.923278,137.037388 C545.505184,137.036091 540.035672,132.251415 539.682051,125.811751 C538.284344,100.424041 528.85789,79 516.284983,79 C507.089544,79 499.122745,90.8871134 495.234203,108.226865 C493.86618,114.328008 487.970774,118.302052 481.830157,117.298165 C477.879667,116.652253 474.400242,117.199592 471.677102,119.116576 C457.114108,129.362969 472.283678,170.064498 497.705679,193.801111 C500.62757,196.528727 502.100131,200.510553 501.438059,204.461251 C499.130488,218.219953 501.311582,229.090209 508.254945,232.732218 C519.803125,238.789263 540.14021,222.559754 553.68364,196.484629 C567.225779,170.408206 568.842885,144.35902 557.294705,138.301975" id="Fill-11" fill="#EFACB0" />
          <polygon id="Fill-12" fill="#EC989D" points="516 261 518 261 518 136 516 136" />
          <polyline id="Fill-13" fill="#EC989D" points="544.160118 169 516 213.888059 517.841188 215 546 170.111941" />
          <polyline id="Fill-14" fill="#EC989D" points="485 139.186443 516.1735 186 518 184.81225 486.827823 138" />
          <path d="M82,127.642878 C82,115.386552 87.2690494,102.720901 95.666354,98.2586208 C101.808827,94.9944252 107.08306,90.2384715 110.237232,84.0258444 C115.95984,72.7570928 123.247871,66 131.18773,66 C145.467035,66 157.641831,87.8332063 162.386049,118.510408 C163.618431,126.485076 167.324649,133.963359 173.257189,139.415813 C181.592291,147.077317 187,159.390819 187,173.284441 C187,193.757133 174.858897,211.844051 158.32476,214.91333 C152.467381,216.000963 147.182781,218.980581 142.752166,222.976361 C139.149619,226.222365 135.255498,228 131.18773,228 C116.427653,228 103.914632,204.675035 99.5358527,172.364437 C98.547096,165.062879 95.4097697,158.243413 90.4608027,152.797456 C85.3343001,147.156583 82,137.992925 82,127.642878" id="Fill-15" fill="#EDDEE6" />
          <polygon id="Fill-16" fill="#BFADC8" points="131 280 133 280 133 116 131 116" />
          <polyline id="Fill-17" fill="#BFADC8" points="161.405884 177 133.10485 212.866937 117.516794 195.362096 116 196.686249 133.198925 216 163 178.232304 161.405884 177" />
          <polyline id="Fill-18" fill="#BFADC8" points="105 131.364366 132.31868 162 143 153.740757 141.752328 152.119431 132.574979 159.21518 106.522186 130 105 131.364366" />
          <path d="M85.2290495,174.41558 C83.7968079,173.660548 82.2102852,173.297977 80.5080518,173.297977 C74.8677966,173.296677 70.0619443,169.06668 69.7508111,163.374702 C68.521706,140.935573 60.2381035,122 49.1903002,122 C41.1085486,122 34.1080495,132.506768 30.690726,147.833523 C29.4886201,153.225308 24.3073508,156.73796 18.9100881,155.850375 C15.4387663,155.279878 12.3814317,155.763306 9.98879094,157.457904 C-2.80881568,166.513087 10.522346,202.489507 32.8635164,223.469254 C35.4297232,225.879898 36.723112,229.400347 36.141987,232.890907 C34.1144779,245.051987 36.031419,254.659474 42.1332314,257.87973 C52.2810623,263.232528 70.1532273,248.888224 82.0547186,225.840912 C93.9562099,202.792299 95.3768805,179.769678 85.2290495,174.41558" id="Fill-19" fill="#BFADC8" />
          <polygon id="Fill-20" fill="#AD99BD" points="49 284 51 284 51 173 49 173" />
          <polyline id="Fill-21" fill="#AD99BD" points="72.4055695 202 48 242.008692 49.5944305 243 74 202.991308" />
          <polyline id="Fill-22" fill="#AD99BD" points="21 176.014689 47.4492503 216 49 214.985311 22.5494775 175" />
          <path d="M480.451521,147 L323.549771,147 C320.484355,147 318,149.490895 318,152.563084 L318,337.436916 C318,340.509105 320.484355,343 323.549771,343 L480.451521,343 C483.515645,343 486,340.509105 486,337.436916 L486,152.563084 C486,149.490895 483.515645,147 480.451521,147" id="Fill-23" fill="#E2DDE7" />
          <polygon id="Fill-24" fill="#FFFFFE" points="334 325 470 325 470 171 334 171" />
          <path d="M342.650785,163 L336.350423,163 C335.605154,163 335,162.274442 335,161.379104 L335,156.620896 C335,155.725558 335.605154,155 336.350423,155 L342.650785,155 C343.396054,155 344,155.725558 344,156.620896 L344,161.379104 C344,162.274442 343.396054,163 342.650785,163" id="Fill-25" fill="#B0AFCC" />
          <path d="M355.649758,163 L349.350242,163 C348.603865,163 348,162.274442 348,161.379104 L348,156.620896 C348,155.725558 348.603865,155 349.350242,155 L355.649758,155 C356.394928,155 357,155.725558 357,156.620896 L357,161.379104 C357,162.274442 356.394928,163 355.649758,163" id="Fill-26" fill="#B0AFCC" />
          <path d="M370.650785,163 L364.350423,163 C363.605154,163 363,162.274442 363,161.379104 L363,156.620896 C363,155.725558 363.605154,155 364.350423,155 L370.650785,155 C371.396054,155 372,155.725558 372,156.620896 L372,161.379104 C372,162.274442 371.396054,163 370.650785,163" id="Fill-27" fill="#B0AFCC" />
          <polygon id="Fill-28" fill="#B0AFCC" points="382 160 469 160 469 156 382 156" />
          <path d="M351,305 L454,305 L454,254 L351,254 L351,305 Z M352.143956,303.861135 L452.857338,303.861135 L452.857338,255.138865 L352.143956,255.138865 L352.143956,303.861135 Z" id="Fill-29" fill="#B0AFCC" />
          <polygon id="Fill-30" fill="#B0AFCC" points="377 190 451 190 451 182 377 182" />
          <polygon id="Fill-31" fill="#B0AFCC" points="377 205 451 205 451 197 377 197" />
          <polygon id="Fill-32" fill="#B0AFCC" points="377 220 451 220 451 212 377 212" />
          <polygon id="Fill-33" fill="#B0AFCC" points="377 235 451 235 451 227 377 227" />
          <path d="M360.913591,190 L356.086409,190 C353.829544,190 352,188.209142 352,186 C352,183.790858 353.829544,182 356.086409,182 L360.913591,182 C363.171735,182 365,183.790858 365,186 C365,188.209142 363.171735,190 360.913591,190" id="Fill-34" fill="#B0AFCC" />
          <path d="M360.913591,205 L356.086409,205 C353.829544,205 352,203.209142 352,201 C352,198.790858 353.829544,197 356.086409,197 L360.913591,197 C363.171735,197 365,198.790858 365,201 C365,203.209142 363.171735,205 360.913591,205" id="Fill-35" fill="#B0AFCC" />
          <path d="M360.913591,220 L356.086409,220 C353.829544,220 352,218.20817 352,215.999374 C352,213.79183 353.829544,212 356.086409,212 L360.913591,212 C363.171735,212 365,213.79183 365,215.999374 C365,218.20817 363.171735,220 360.913591,220" id="Fill-36" fill="#B0AFCC" />
          <path d="M360.913591,235 L356.086409,235 C353.829544,235 352,233.20817 352,230.999374 C352,228.79183 353.829544,227 356.086409,227 L360.913591,227 C363.171735,227 365,228.79183 365,230.999374 C365,233.20817 363.171735,235 360.913591,235" id="Fill-37" fill="#B0AFCC" />
          <path d="M234,312.903316 L234,209.563477 C234,206.490491 231.515626,204 228.451478,204 L71.548522,204 C68.4843743,204 66,206.490491 66,209.563477 L66,312.903316 C66,317.375585 69.6155135,321 74.0780902,321 L225.92191,321 C230.383195,321 234,317.375585 234,312.903316" id="Fill-38" fill="#FADEDA" />
          <polygon id="Fill-39" fill="#FFFFFE" points="82 309 218 309 218 229 82 229" />
          <path d="M90.6497585,221 L84.3502415,221 C83.6050725,221 83,220.274442 83,219.379104 L83,214.622347 C83,213.725558 83.6050725,213 84.3502415,213 L90.6497585,213 C91.3949275,213 92,213.725558 92,214.622347 L92,219.379104 C92,220.274442 91.3949275,221 90.6497585,221" id="Fill-40" fill="#EFACB0" />
          <path d="M104.649577,221 L98.3492149,221 C97.6039458,221 97,220.274442 97,219.379104 L97,214.622347 C97,213.725558 97.6039458,213 98.3492149,213 L104.649577,213 C105.394846,213 106,213.725558 106,214.622347 L106,219.379104 C106,220.274442 105.394846,221 104.649577,221" id="Fill-41" fill="#EFACB0" />
          <path d="M118.649577,221 L112.350423,221 C111.603946,221 111,220.274442 111,219.379104 L111,214.622347 C111,213.725558 111.603946,213 112.350423,213 L118.649577,213 C119.396054,213 120,213.725558 120,214.622347 L120,219.379104 C120,220.274442 119.396054,221 118.649577,221" id="Fill-42" fill="#EFACB0" />
          <polygon id="Fill-43" fill="#EFACB0" points="130 219 217 219 217 215 130 215" />
          <polygon id="Fill-44" fill="#EFACB0" points="126 249 200 249 200 241 126 241" />
          <polygon id="Fill-45" fill="#EFACB0" points="126 263 200 263 200 255 126 255" />
          <polygon id="Fill-46" fill="#EFACB0" points="126 278 200 278 200 270 126 270" />
          <polygon id="Fill-47" fill="#EFACB0" points="126 293 200 293 200 285 126 285" />
          <path d="M108.913993,249 L104.086007,249 C101.829364,249 100,247.210114 100,244.999374 C100,242.789886 101.829364,241 104.086007,241 L108.913993,241 C111.170636,241 113,242.789886 113,244.999374 C113,247.210114 111.170636,249 108.913993,249" id="Fill-48" fill="#EFACB0" />
          <path d="M108.913993,263 L104.086007,263 C101.829364,263 100,261.210394 100,259.001252 C100,256.790858 101.829364,255 104.086007,255 L108.913993,255 C111.170636,255 113,256.790858 113,259.001252 C113,261.210394 111.170636,263 108.913993,263" id="Fill-49" fill="#EFACB0" />
          <path d="M108.913993,278 L104.086007,278 C101.829364,278 100,276.209142 100,274.001252 C100,271.790858 101.829364,270 104.086007,270 L108.913993,270 C111.170636,270 113,271.790858 113,274.001252 C113,276.209142 111.170636,278 108.913993,278" id="Fill-50" fill="#EFACB0" />
          <path d="M108.913993,293 L104.086007,293 C101.829364,293 100,291.209142 100,289.001252 C100,286.790858 101.829364,285 104.086007,285 L108.913993,285 C111.170636,285 113,286.790858 113,289.001252 C113,291.209142 111.170636,293 108.913993,293" id="Fill-51" fill="#EFACB0" />
          <path d="M365.822768,343 L215.177232,343 C207.899094,343 202,337.080305 202,329.778353 L202,72.2203495 C202,64.9183972 207.899094,59 215.177232,59 L365.822768,59 C373.100906,59 379,64.9183972 379,72.2203495 L379,329.778353 C379,337.080305 373.100906,343 365.822768,343" id="Fill-52" fill="#716FA5" />
          <path d="M356.598425,343 L201.400285,343 C194.000128,343 188,336.964792 188,329.52137 L188,72.4786304 C188,65.0352077 194.000128,59 201.400285,59 L356.598425,59 C363.999872,59 370,65.0352077 370,72.4786304 L370,329.52137 C370,336.964792 363.999872,343 356.598425,343" id="Fill-53" fill="#817DAF" />
          <path d="M347.690909,328 L210.309091,328 C205.167532,328 201,323.847883 201,318.726638 L201,82.2733625 C201,77.1521167 205.167532,73 210.309091,73 L347.690909,73 C352.832468,73 357,77.1521167 357,82.2733625 L357,318.726638 C357,323.847883 352.832468,328 347.690909,328" id="Fill-54" fill="#9D9AC0" />
          <path d="M303.711388,83 L251.287315,83 C246.710486,83 243,79.4998118 243,75.1836062 L243,70 L312,70 L312,75.1836062 C312,79.4998118 308.289514,83 303.711388,83" id="Fill-55" fill="#817DAF" />
          <path d="M151,341.443458 C142.329759,341.443458 135.300872,334.306187 135.300872,325.50065 C135.300872,316.693813 142.329759,309.556542 151,309.556542 C159.670241,309.556542 166.699128,316.693813 166.699128,325.50065 C166.699128,334.306187 159.670241,341.443458 151,341.443458 Z M183,332.524795 L183,318.475205 L177.010723,318.475205 C176.391054,316.104783 175.464111,313.86309 174.278547,311.793034 L178.517644,307.486487 L168.737377,297.552302 L164.49828,301.858849 C162.45747,300.654784 160.25022,299.713377 157.91622,299.084038 L157.91622,293 L144.08378,293 L144.08378,299.084038 C141.74978,299.713377 139.54253,300.654784 137.503001,301.858849 L133.262623,297.552302 L123.481075,307.486487 L127.721453,311.793034 C126.535889,313.86309 125.608946,316.104783 124.989277,318.475205 L119,318.475205 L119,332.524795 L124.989277,332.524795 C125.608946,334.895217 126.535889,337.13691 127.721453,339.208266 L123.481075,343.513513 L133.262623,353.447698 L137.503001,349.141151 C139.54253,350.345216 141.74978,351.287923 144.08378,351.915962 L144.08378,358 L157.91622,358 L157.91622,351.915962 C160.25022,351.287923 162.45747,350.345216 164.49828,349.141151 L168.737377,353.447698 L178.517644,343.513513 L174.278547,339.208266 C175.464111,337.13691 176.391054,334.895217 177.010723,332.524795 L183,332.524795 L183,332.524795 Z" id="Fill-56" fill="#817DAF" />
          <path d="M108,345.774533 C101.497531,345.774533 96.225467,340.502469 96.225467,334 C96.225467,327.497531 101.497531,322.225467 108,322.225467 C114.502469,322.225467 119.774533,327.497531 119.774533,334 C119.774533,340.502469 114.502469,345.774533 108,345.774533 Z M132,339.18703 L132,328.811681 L127.507408,328.811681 C127.04359,327.062057 126.347863,325.406485 125.458879,323.875886 L128.638609,320.697445 L121.302555,313.360103 L118.122826,316.541121 C116.593515,315.652137 114.937943,314.95641 113.18703,314.491303 L113.18703,310 L102.81297,310 L102.81297,314.491303 C101.062057,314.95641 99.4064849,315.652137 97.8771741,316.541121 L94.6974447,313.360103 L87.3613915,320.697445 L90.5411209,323.875886 C89.6521366,325.406485 88.9564097,327.062057 88.4925918,328.811681 L84,328.811681 L84,339.18703 L88.4925918,339.18703 C88.9564097,340.937943 89.6521366,342.593515 90.5411209,344.122826 L87.3613915,347.302555 L94.6974447,354.63732 L97.8771741,351.458879 C99.4064849,352.347863 101.062057,353.042302 102.81297,353.507408 L102.81297,358 L113.18703,358 L113.18703,353.507408 C114.937943,353.042302 116.593515,352.347863 118.122826,351.458879 L121.302555,354.63732 L128.638609,347.302555 L125.458879,344.122826 C126.347863,342.593515 127.04359,340.937943 127.507408,339.18703 L132,339.18703 L132,339.18703 Z" id="Fill-57" fill="#95CDB1" />
          <path d="M510.843209,371 L191.156791,371 C189.966481,371 189,370.104979 189,369 C189,367.895021 189.966481,367 191.156791,367 L510.843209,367 C512.034813,367 513,367.895021 513,369 C513,370.104979 512.034813,371 510.843209,371" id="Fill-58" fill="#C7E4E3" />
          <path d="M344.385511,272 L213.615783,272 C210.514877,272 208,274.49992 208,277.583969 L208,306.414745 C208,309.498794 210.514877,312 213.615783,312 L344.385511,312 C347.486417,312 350,309.498794 350,306.414745 L350,277.583969 C350,274.49992 347.486417,272 344.385511,272 M344.385511,273.062755 C346.891332,273.062755 348.930142,275.090482 348.930142,277.583969 L348.930142,306.414745 C348.930142,308.906945 346.891332,310.935958 344.385511,310.935958 L213.615783,310.935958 C211.109961,310.935958 209.069858,308.906945 209.069858,306.414745 L209.069858,277.583969 C209.069858,275.090482 211.109961,273.062755 213.615783,273.062755 L344.385511,273.062755" id="Fill-59" fill="#FFFFFE" />
          <polygon id="Fill-60" fill="#C7E4E3" points="300 357 389 357 389 300 300 300" />
          <polyline id="Fill-61" fill="#B3D4D2" points="344.5 329 300 357 389 357 344.5 329" />
          <polyline id="Fill-62" fill="#A2C2C0" points="389 300 300 300 344.5 340 389 300" />
          <path d="M331.501106,266.992891 C330.001341,265.424154 332.170714,263.053506 334.029431,264.416754 C353.668657,278.82859 371.034087,283.338084 383.776219,262.60619 C384.727767,261.006128 386.786192,257.359941 387.287419,255.584461 C388.223304,252.276579 391.776273,250.320669 395.22221,251.217807 C398.96836,252.192629 400.990237,256.098184 399.515272,259.565195 C391.867643,277.539268 365.495789,302.5263 331.501106,266.992891" id="Fill-63" fill="#EA8F84" />
          <g id="Group-66" transform="translate(171.000000, 137.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <g id="Clip-65" />
            <polygon id="Fill-64" fill="#B9D6CD" mask="url(#mask-2)" points="0.29971978 40.4375312 17.3204011 40.4375312 17.3204011 0.60475 0.29971978 0.60475" />
          </g>
          <path d="M186.716585,177 L163.103495,177 L163.218179,176.855898 L161.587554,175.613014 L160.477621,177 L149.72103,177 C146.561281,177 144,174.498794 144,171.414745 L144,142.583969 C144,139.49992 146.561281,137 149.72103,137 L171.785224,137 C172.18596,137.397568 172.597241,137.784844 173.020386,138.163112 C181.499111,145.749075 187,157.941169 187,171.697803 C187,173.486217 186.903771,175.255331 186.716585,177" id="Fill-67" fill="#C5B9CC" />
          <polyline id="Fill-68" fill="#A791B8" points="162.916306 178 161 178 161.810005 177 163 177.896104 162.916306 178" />
          <polygon id="Fill-69" fill="#6D6BA1" points="189 177 202 177 202 137 189 137" />
          <path d="M280.378656,177 L201,177 L201,137 L280.378656,137 C283.482633,137 286,139.49992 286,142.583969 L286,171.414745 C286,174.498794 283.482633,177 280.378656,177" id="Fill-70" fill="#8582B1" />
          <path d="M280.384217,176 L149.614489,176 C146.513583,176 144,173.498794 144,170.414745 L144,141.585255 C144,138.501206 146.513583,136 149.614489,136 L280.384217,136 C283.485123,136 286,138.501206 286,141.585255 L286,170.414745 C286,173.498794 283.485123,176 280.384217,176" id="Fill-71" fill="#FFFFFE" />
          <path d="M344.385511,98 L213.615783,98 C210.514877,98 208,100.501206 208,103.585255 L208,132.416031 C208,135.50008 210.514877,138 213.615783,138 L344.385511,138 C347.486417,138 350,135.50008 350,132.416031 L350,103.585255 C350,100.501206 347.486417,98 344.385511,98 Z M344.385511,99.0653286 C346.891332,99.0653286 348.930142,101.093055 348.930142,103.585255 L348.930142,132.416031 C348.930142,134.908231 346.891332,136.937245 344.385511,136.937245 L213.615783,136.937245 C211.109961,136.937245 209.069858,134.908231 209.069858,132.416031 L209.069858,103.585255 C209.069858,101.093055 211.109961,99.0653286 213.615783,99.0653286 L344.385511,99.0653286 L344.385511,99.0653286 Z" id="Fill-72" fill="#FFFFFE" />
          <path d="M344.385511,157 L213.615783,157 C210.514877,157 208,159.49992 208,162.585255 L208,191.414745 C208,194.498794 210.514877,197 213.615783,197 L344.385511,197 C347.486417,197 350,194.498794 350,191.414745 L350,162.585255 C350,159.49992 347.486417,157 344.385511,157 Z M344.385511,158.064042 C346.891332,158.064042 348.930142,160.091769 348.930142,162.585255 L348.930142,191.414745 C348.930142,193.906945 346.891332,195.935958 344.385511,195.935958 L213.615783,195.935958 C211.109961,195.935958 209.069858,193.906945 209.069858,191.414745 L209.069858,162.585255 C209.069858,160.091769 211.109961,158.064042 213.615783,158.064042 L344.385511,158.064042 L344.385511,158.064042 Z" id="Fill-73" fill="#FFFFFE" />
          <path d="M344.385511,215 L213.615783,215 C210.514877,215 208,217.498794 208,220.583041 L208,249.416959 C208,252.49992 210.514877,255 213.615783,255 L344.385511,255 C347.486417,255 350,252.49992 350,249.416959 L350,220.583041 C350,217.498794 347.486417,215 344.385511,215 Z M344.385511,216.062824 C346.891332,216.062824 348.930142,218.090681 348.930142,220.583041 L348.930142,249.416959 C348.930142,251.909319 346.891332,253.937176 344.385511,253.937176 L213.615783,253.937176 C211.109961,253.937176 209.069858,251.909319 209.069858,249.416959 L209.069858,220.583041 C209.069858,218.090681 211.109961,216.062824 213.615783,216.062824 L344.385511,216.062824 L344.385511,216.062824 Z" id="Fill-74" fill="#FFFFFE" />
          <path d="M173.727033,168 L156.272967,168 C155.01767,168 154,166.991037 154,165.746479 L154,145.253521 C154,144.008963 155.01767,143 156.272967,143 L173.727033,143 C174.98233,143 176,144.008963 176,145.253521 L176,165.746479 C176,166.991037 174.98233,168 173.727033,168" id="Fill-75" fill="#74C2E1" />
          <polygon id="Fill-76" fill="#74C2E1" points="186 147 244 147 244 143 186 143" />
          <polygon id="Fill-77" fill="#74C2E1" points="184 157 274 157 274 153 184 153" />
          <polygon id="Fill-78" fill="#74C2E1" points="184 168 274 168 274 164 184 164" />
          <path d="M300.891038,307 L232.612305,307 C229.512606,307 227,304.542443 227,301.512214 L227,273.185834 C227,273.122625 227,273.061945 227.002586,273 L339.31593,273 C342.989791,276.092173 346.556321,278.468823 350,280.227291 L350,299.647555 L300.891038,299.647555 L300.891038,307" id="Fill-79" fill="#8582B1" />
          <path d="M368,300 L356,300 L356,282 C359.029629,282.855695 361.950278,283.246909 364.746963,283.246909 C365.85038,283.246909 366.934726,283.187466 368,283.069964 L368,300" id="Fill-80" fill="#6D6BA1" />
          <path d="M357,300 L350.486891,300 L350.486891,280.485696 C352.713351,281.578602 354.882655,282.405136 357,282.997942 L357,300 M337.461972,271.397737 L226,271.397737 C226.572852,268.878962 228.816199,267 231.496004,267 L332.954496,267 C334.474308,268.591699 335.974635,270.052823 337.461972,271.397737" id="Fill-81" fill="#8582B1" />
          <path d="M350,300 L348.931135,300 L348.931135,280.36357 C349.289146,280.547638 349.645866,280.725314 350,280.896599 L350,300 M338.252822,273.055832 L226,273.055832 C226.011632,272.694088 226.059453,272.341292 226.138293,272 L337.040494,272 C337.446326,272.360466 337.850867,272.713262 338.252822,273.055832" id="Fill-82" fill="#D6D5D0" />
          <polyline id="Fill-83" fill="#A6BDBC" points="307.396534 308 299 308 299 300 368 300 299 300 307.396534 308" />
          <path d="M362.308346,308 L307.396534,308 L299,300 L368,300 L368,302.028886 C368,305.325997 365.451146,308 362.308346,308" id="Fill-84" fill="#86A2A2" />
          <path d="M361.521172,275 C359.718924,275 357.877171,274.812787 356,274.453044 L356,267 L362.086502,267 C365.351799,267 368,269.377485 368,272.311716 L368,274.114102 C365.902146,274.714898 363.741628,275 361.521172,275" id="Fill-85" fill="#6D6BA1" />
          <path d="M358,276 C352.309377,274.838943 346.283368,272.008537 340,268 L358,268 L358,276" id="Fill-86" fill="#8582B1" />
          <path d="M363.028111,285 C360.473133,285 357.804905,284.612741 355.037116,283.765699 C353.008565,283.144443 350.930234,282.278242 348.797145,281.132887 C348.456149,280.949521 348.112665,280.759313 347.767936,280.562262 C344.453807,278.658811 341.021449,276.08621 337.485798,272.739091 C337.098755,272.372358 336.709224,271.994678 336.318448,271.608788 C334.893485,270.199331 333.456076,268.668086 332,267 L338.67306,267 C344.385359,271.176372 349.86369,274.125285 355.037116,275.334955 C356.75205,275.737266 358.434627,275.946632 360.081113,275.946632 C362.109663,275.946632 364.083455,275.627794 366,274.955907 L366,284.824844 C365.026794,284.941159 364.036164,285 363.028111,285" id="Fill-87" fill="#C7796F" />
          <path d="M362.384217,306 L231.614489,306 C228.513583,306 226,303.501287 226,300.415852 L226,271.584148 C226,268.501287 228.513583,266 231.614489,266 L362.384217,266 C365.485123,266 368,268.501287 368,271.584148 L368,300.415852 C368,303.501287 365.485123,306 362.384217,306" id="Fill-88" fill="#FFFFFE" />
          <path d="M254.727033,294 L237.271676,294 C236.01767,294 235,292.991089 235,291.746594 L235,271.253406 C235,270.008911 236.01767,269 237.271676,269 L254.727033,269 C255.98233,269 257,270.008911 257,271.253406 L257,291.746594 C257,292.991089 255.98233,294 254.727033,294" id="Fill-89" fill="#95CDB1" />
          <polygon id="Fill-90" fill="#95CDB1" points="285 276 343 276 343 272 285 272" />
          <polygon id="Fill-91" fill="#95CDB1" points="268 286 358 286 358 282 268 282" />
          <polygon id="Fill-92" fill="#95CDB1" points="268 297 358 297 358 293 268 293" />
          <path d="M193.027455,306.651216 C193.24391,310.793554 195.728117,333.654541 197.457246,348.376873 C198.33062,355.82642 198.45395,361.191989 199.129744,360.994735 C200.872717,360.483667 206.313055,312.18458 205.985854,305.946737 C205.658654,299.710176 202.492359,294.812125 198.914547,295.005536 C195.335477,295.20151 192.700254,300.414655 193.027455,306.651216" id="Fill-93" fill="#EA8F84" />
          <path d="M200.426023,350.379268 C199.776955,350.411463 196.006862,350.859627 195.310684,351 C193.053339,336.129465 190.377241,311.224185 190.614098,306.852013 C190.64027,306.381957 190.682146,305.918339 190.739724,305.463736 C191.537974,299.222928 191.579849,292.90485 190.136457,286.777371 C186.857092,272.84695 184.317088,257.734304 184.042281,253.608107 C183.496592,245.416242 188.296558,238.438797 194.762378,238.021541 C201.228198,237.605573 206.554223,243.255268 207.586713,251.399484 C210.082224,271.058159 200.462664,350.053447 200.426023,350.379268" id="Fill-94" fill="#196997" />
          <path d="M206.696684,285.97259 L206.729399,286 C207.86526,270.528827 208.448893,256.464717 207.586529,249.580809 C206.554047,241.326384 201.228064,235.600235 194.762294,236.021832 C188.296524,236.444734 183.496596,243.5166 184.042281,251.819319 C184.109019,252.82567 184.307925,254.484647 184.615445,256.612211 C194.224461,260.364815 201.093278,269.166142 202.980273,279.567709 C203.433047,282.069881 204.736408,284.347549 206.696684,285.97259" id="Fill-95" fill="#24568C" />
          <path d="M196.161264,350 C196.161264,350 196.569176,361.287976 195.62752,363.438714 C194.685864,365.589452 194.456327,368.021607 197.455522,368 C200.396643,367.982182 207.45699,367.975384 208.474698,367.944115 C210.364924,367.885657 212.038058,367.672214 211.999341,366.441862 C211.962007,365.21015 203.228525,364.830847 201.560922,363.590978 C199.891937,362.34975 201.121206,350.600902 201.121206,350.600902 L196.161264,350" id="Fill-96" fill="#EA8F84" />
          <path d="M207.634896,364.812352 C205.971262,365.453683 204.199762,365.822264 202.363266,365.822264 C199.842232,365.822264 197.442894,365.152921 195.293861,364 C194.682617,366.043412 194.856863,368.02048 197.453957,367.99984 C200.395394,367.980674 207.456501,367.973302 208.474319,367.939393 C210.364748,367.875997 212.038062,367.644528 211.999341,366.310265 C211.982746,365.664511 209.933282,365.235483 207.634896,364.812352" id="Fill-97" fill="#8F3A27" />
          <path d="M207.027455,308.651431 C207.245169,312.792563 209.728117,335.655252 211.455988,350.377856 C212.331879,357.826258 212.45395,363.193207 213.129744,362.994668 C214.872717,362.484872 220.313055,314.183615 219.985854,307.946939 C219.658654,301.710263 216.492359,296.812121 212.914547,297.005536 C209.335477,297.200232 206.700254,302.414755 207.027455,308.651431" id="Fill-98" fill="#EA8F84" />
          <path d="M216.425371,351.380549 C215.775019,351.411457 212.005076,351.860913 211.310234,352 C209.052979,337.129313 206.376987,312.223777 206.613835,307.852849 C206.640007,307.382787 206.68188,306.917877 206.740765,306.464557 C207.536366,300.222397 207.579548,293.905543 206.136213,287.776713 C202.856978,273.847437 200.317075,258.733348 200.042279,254.607109 C199.496612,246.416447 204.296387,239.438931 210.76195,239.02167 C217.227514,238.60441 222.553327,244.254164 223.587084,252.398463 C226.081188,272.058627 216.463319,351.054726 216.425371,351.380549" id="Fill-99" fill="#1A86B5" />
          <path d="M211.157341,352 C211.157341,352 211.55196,363.28504 210.64099,365.435218 C209.73002,367.585397 209.388908,370.125652 212.409423,369.995174 C215.252024,369.874209 220.766001,369.686646 221.747869,369.580632 C224.199864,369.318316 227.035776,369.40802 226.999659,368.176628 C226.962203,366.946595 217.994298,366.82699 216.379701,365.587443 C214.766441,364.346537 215.955651,352.599386 215.955651,352.599386 L211.157341,352" id="Fill-100" fill="#EA8F84" />
          <path d="M221.479598,365.437013 C219.876827,366.084272 218.169702,366.455207 216.399697,366.455207 C214.245722,366.455207 212.17871,365.931092 210.290972,365 C209.702308,367.07994 209.822716,369.117831 212.407486,368.994687 C215.250464,368.86103 220.765174,368.653787 221.747172,368.53665 C224.199492,368.246809 227.035781,368.345926 226.999658,366.985329 C226.97959,366.231445 224.215546,365.854503 221.479598,365.437013" id="Fill-101" fill="#BC4D2C" />
          <path d="M208.140778,186 C206.332968,186 204.380533,185.062407 201.689041,182.622376 C200.144742,181.220439 199,178.777864 199,177.126581 L199,168 L212.539579,168 C212.539579,168 213.555629,172.845713 212.568994,177.655806 C211.829936,181.263693 209.560062,186.001272 208.140778,186" id="Fill-102" fill="#F1B4A7" />
          <path d="M158.006158,183.849326 C157.925683,182.909059 158.643361,182.084399 159.609059,182.006043 C160.475814,181.935395 161.247581,182.491591 161.453386,183.286707 L161.459982,183.309828 C162.892699,188.785726 164.515389,194.199967 166.598502,199.282802 C170.858394,209.83126 174.583986,211.339283 176.019341,212.271843 C176.202718,212.323223 176.919077,212.539022 177.107731,212.524893 C181.243613,213.452314 188.465245,204.670069 189.7225,197.86598 C189.723819,197.50503 190.622236,195.132527 190.521972,195.415121 C191.590573,192.429902 194.942814,190.853799 198.007456,191.894258 C216.599795,198.205092 164.590587,261.083505 158.006158,183.849326" id="Fill-103" fill="#F1B4A7" />
          <path d="M187.943971,248.596742 C193.220086,254.52612 223.683536,257 223.683536,257 C225.932779,254.445492 215.455046,225.278534 215.455046,225.278534 C215.455046,225.278534 220.502446,204.228156 220.276345,196.626062 C220.182246,193.489238 217.560525,191 214.35591,191 L195.023662,191 C192.04384,191 189.563269,193.226876 189.318871,196.135893 C188.811779,202.153578 196.840308,224.61943 196.840308,224.61943 C196.840308,224.61943 183.381446,243.469808 187.943971,248.596742" id="Fill-104" fill="#F1B4A7" />
          <path d="M216.563972,225.278534 C216.563972,225.278534 221.526386,203.613845 221.309619,196.139732 C221.225751,193.27039 218.858088,191 215.965282,191 L213.134409,191 C212.057026,193.480279 209.773232,195.394881 206.222383,195.394881 C202.676695,195.394881 200.392901,193.480279 199.312937,191 L196.656252,191 C199.543897,197.132868 198.505222,205.222571 193.537647,209.943805 C195.648544,217.342408 198.186523,224.61943 198.186523,224.61943 C198.186523,224.61943 184.158606,245.635253 186.203699,248.75288 C190.528715,255.345201 224.68757,257 224.68757,257 C226.908141,253.786387 216.563972,225.278534 216.563972,225.278534" id="Fill-105" fill="#E9844D" />
          <polyline id="Fill-106" fill="#F1B4A7" points="203 193 211 192.037654 208.468852 182 203.293294 182.734062 203 193" />
          <path d="M202.222179,181.746611 L208.103155,181 L209,184.186653 C207.684536,185.710462 205.993807,189 203.8533,189 C203.174569,189 202.556803,188.802572 202,188.441084 L202.222179,181.746611" id="Fill-107" fill="#EA8F84" />
          <path d="M201.724574,163.18394 L206.351779,162.888809 C207.674904,162.805022 208.935852,162.37358 210.02146,161.659512 C211.594536,160.622801 213.677462,160.80038 214.888669,162.248524 C216.196871,163.81297 216.857189,165.978933 213.933631,168.212426 C212.481179,169.32167 210.190582,168.342484 208.743103,168.923993 L208.474499,168.922742 L204.368336,169.186609 C202.93205,169.279151 202.019293,170.548465 202.019293,171.995359 C202.019293,172.873249 201.408715,174 201.408715,174 L199.489936,171.617691 L197.69178,170.152039 C197.301309,169.834398 197.061306,169.36794 197.028974,168.863966 L197.010321,168.570085 C196.833739,165.773842 198.944023,163.36277 201.724574,163.18394" id="Fill-108" fill="#924288" />
          <path d="M255.420877,175.244311 C255.420877,175.244311 253.236448,179.70337 254.284588,180.869586 C255.333935,182.035801 256.23717,182.61179 257.877001,181.039147 C259.51804,179.467797 261.069721,176.472656 261.663828,174.476757 C262.259142,172.480859 261.939146,168.685805 261.654168,168.30397 C260.618103,166.912536 259.894791,170.64805 259.462493,172.43944 C259.028989,174.229535 258.374505,176.206018 257.629457,175.74005 C256.884408,175.272787 257.954283,171.910048 257.264782,171.072599 C256.576487,170.23515 255.420877,175.244311 255.420877,175.244311" id="Fill-109" fill="#F1B4A7" />
          <path d="M159.661286,175.335826 C159.661286,175.335826 161.787337,179.810397 160.680897,180.932562 C159.573217,182.056009 158.63051,182.600439 156.988216,180.997894 C155.344682,179.394068 153.830149,176.386255 153.271968,174.394283 C152.712546,172.403592 153.141725,168.657916 153.443143,168.287704 C154.544622,166.941361 155.188391,170.658855 155.586561,172.443303 C155.98349,174.22647 156.603691,176.201789 157.381424,175.761121 C158.159157,175.321735 157.149469,171.962924 157.877586,171.154606 C158.606943,170.346288 159.661286,175.335826 159.661286,175.335826" id="Fill-110" fill="#F1B4A7" />
          <path d="M230.407208,207.781983 C235.383595,213.503759 239.184508,210.494453 243.512796,204.193079 C245.338912,201.053267 247.611072,198.416286 253.699395,181.15244 L253.70702,181.12941 C254.379264,179.225563 257.215653,179.896004 256.986912,181.894531 C251.004064,234.452469 222.668137,226.183275 214.213615,202.638527 C213.617618,200.895893 213.057203,199.178848 213.001288,196.48813 C212.933937,193.212695 215.516168,190.502784 218.769374,190.434972 C222.022579,190.36716 224.715369,192.967037 224.78145,196.242472 C224.828469,198.468744 226.976092,203.962775 230.407208,207.781983" id="Fill-111" fill="#F1B4A7" />
          <path d="M220.965017,198.438555 C219.21165,197.109568 218.414782,194.946278 218.540942,192.803494 C218.806136,188.257768 216.07181,184.218261 212.563789,181.005366 C210.130703,178.777999 208.97209,175.492055 209.349283,172.224053 L209.8359,168 L195.489703,168 C195.489703,171.508937 193.857347,174.844862 191.009735,176.912034 C187.806815,179.236801 185.144581,182.585541 183.539259,186.704506 C179.625723,196.75201 183.433696,207.640223 192.026739,211.598991 C189.762295,221.101828 193.987369,230.682841 202.358988,233.914959 C211.615014,237.487975 222.405559,231.94775 226.460703,221.538844 C229.885046,212.748559 227.397891,203.312364 220.965017,198.438555" id="Fill-112" fill="#924288" />
          <path d="M400.230406,350.280991 C397.583506,350.003456 395.978092,347.145636 397.050062,344.634734 C401.503052,334.194461 405.107611,322.640122 405.900157,318.271572 C407.513191,309.379994 402.046657,300.866753 393.691902,299.256529 C385.337148,297.647614 382.622933,303.784793 381.011169,312.676371 C379.755034,319.599025 376.398145,346.004079 378.907874,358.134433 C378.898983,358.270582 380.839704,368 390.315962,368 C396.716024,368 451.919892,365.943364 452,360.825994 C452.064684,356.79127 417.84931,352.126857 400.230406,350.280991" id="Fill-113" fill="#3C4946" />
          <path d="M397.450071,340 C401.155748,330.930779 404.007775,321.884965 404.710014,318.143619 C406.367714,309.311076 400.749807,300.854359 392.163704,299.254826 C383.577602,297.656593 380.788228,303.753024 379.131833,312.585567 C379.090064,312.807941 379.045685,313.052422 379,313.312509 C394.092902,322.363524 395.994688,337.918006 397.450071,340" id="Fill-114" fill="#373F3D" />
          <path d="M498.622337,363.727041 C495.421677,361.424632 491.73921,359.962508 487.979604,359.019029 C487.800033,358.974804 487.609081,359.008308 487.454802,359.112841 C486.866771,359.516232 486.415315,359.89684 486.202865,360.083123 C486.109286,360.163533 486.001796,360.21446 485.885454,360.237242 C480.731013,361.187422 474.845643,360.578985 468.787025,359.482728 C468.206581,359.376854 467.788004,360.052299 468.114266,360.570944 L471.594399,366.10316 C471.715799,366.298825 471.921926,366.415419 472.141964,366.41944 C474.062866,366.443563 483.392961,366.572219 488.909073,366.846954 C493.326262,367.068082 497.165537,367.026536 500.35608,366.868396 C500.940318,366.840253 501.215997,366.088418 500.801214,365.648843 C500.144895,364.950615 499.425347,364.303314 498.622337,363.727041" id="Fill-115" fill="#F1B4A7" />
          <path d="M498.176553,361.11275 L492.549761,358.294129 C490.094265,357.114961 486.519253,359.834596 486,360.246624 C486.126161,360.242912 486.24701,360.228064 486.373172,360.228064 C491.350565,360.228064 495.868466,362.545569 498.725023,366.06204 C499.026482,366.434474 499.488631,366.675752 499.99062,366.696787 C505.115423,366.913318 508.824564,366.978896 510.823224,367 C511.928131,367.011067 512.43676,365.675996 511.540351,365.074657 C508.556305,363.073906 500.115453,362.20778 498.176553,361.11275" id="Fill-116" fill="#3C4946" />
          <path d="M425.988851,349.129567 C422.955525,348.840793 420.736739,346.15841 421.016817,343.126933 C422.129422,331.108831 421.92643,317.926652 421.170989,313.457725 C419.694796,304.71367 411.625198,298.782909 403.14576,300.212658 C394.667608,301.641123 394.169119,308.236704 395.646597,316.980759 C396.788752,323.741904 401.79162,350.060058 407.921225,360.993656 L408.025291,361.181038 C409.793125,364.275404 411.650893,366.083766 413.536926,365.765474 L413.549773,365.761624 C415.087635,366.371256 474.577313,365.619162 474.577313,365.619162 C474.577313,365.619162 474.745617,361.120716 475,359.518987 C463.397303,354.516151 438.650193,350.330863 425.988851,349.129567" id="Fill-117" fill="#516263" />
          <path d="M378,266.029838 C379.571511,263.953373 385.681306,255.845826 386.669367,253.765472 L386.717661,253.661778 C386.79728,253.495868 386.884731,253.331255 386.980013,253.170529 C388.779929,250.116751 392.732175,249.091481 395.807303,250.8789 C398.882432,252.666319 399.914872,256.591124 398.114955,259.644902 C396.425984,262.509439 394.812716,264.839954 392.977558,267.28842 C391.737587,268.903448 390.427133,270.464038 389.077522,272 C388.174301,270.950102 377.988263,266.045392 378,266.029838" id="Fill-118" fill="#62C0C6" />
          <path d="M407.202513,282.09074 C408.493379,282.346292 409.785504,282.603116 411.077628,282.858669 C415.613274,283.758824 419.835639,280.244658 419.838156,275.573512 C419.839414,267.850991 419.924968,260.826473 419.999199,256.13117 C420.052042,252.811529 417.482891,250.058934 414.201625,249.91018 L394.402053,249.00621 C391.349771,248.866355 388.703872,251.10403 388.316361,254.165576 C387.782904,258.367573 387.530015,264.737318 387.020462,271.699538 C386.747443,275.426029 389.233555,278.811783 392.859556,279.54157 C397.615378,280.498939 402.449207,281.15117 407.202513,282.09074" id="Fill-119" fill="#F1B4A7" />
          <path d="M392.076169,250.005924 L395.451335,250.15376 C396.504672,252.855948 398.895139,255.01307 402.753414,255.181474 C406.618288,255.351165 409.198831,253.413868 410.495043,250.815809 L413.574535,250.950789 C416.723985,251.089627 419.189691,253.628552 419.138212,256.698402 C419.003575,264.69698 418.822739,280.114362 419.118412,294.073951 C419.386366,306.709443 423.76735,317.479628 421.192087,320.809155 C421.192087,320.809155 386.563971,322.809442 378,313.858285 C379.671083,305.51904 380.671621,294.774566 382.135469,283.697139 C383.594037,272.672419 385.075044,261.424016 385.913225,255.046494 C386.306577,252.057632 388.986117,249.870943 392.076169,250.005924" id="Fill-120" fill="#37B4BD" />
          <path d="M402,242.915527 L402.422015,242.960844 C405.639177,243.312056 408.563742,241.261433 408.954809,238.382504 L410,230.678506 L403.752769,230 L402,242.915527" id="Fill-121" fill="#1A4D79" />
          <path d="M402.029029,251.93854 L410,250.160271 L404.032255,239 L397,242.163731 L398.874727,249.882328 C399.230465,251.345784 400.622308,252.250517 402.029029,251.93854" id="Fill-122" fill="#F1B4A7" />
          <path d="M397,242.694668 L402.966432,240 L405,243.81795 C404.066992,245.884547 402.933152,248.1758 400.681538,248.863055 C399.966576,249.080465 399.164395,249.021281 398.471238,248.809911 L397,242.694668" id="Fill-123" fill="#EA8F84" />
          <path d="M397.424356,246.935845 L397.424356,246.935845 C402.072597,247.513296 406.298158,244.128195 406.861731,239.377175 L408,229.791741 L393.564623,228 C393.564623,228 392.676007,235.482856 393.128349,240.663489 C393.468223,244.548963 395.055125,246.642697 397.424356,246.935845" id="Fill-124" fill="#F1B4A7" />
          <polyline id="Fill-125" fill="#1A4D79" points="406.103672 234 408 234.211569 407.895464 235 406 234.789223 406.103672 234" />
          <path d="M404.046918,237.251593 C403.794622,239.179008 404.585147,240.854909 405.810181,240.99435 C407.035214,241.131184 408.71578,238.755472 408.968075,236.828057 C409.218969,234.898035 407.94628,234.146095 406.721246,234.009261 C405.496213,233.869819 404.299213,235.321571 404.046918,237.251593" id="Fill-126" fill="#F1B4A7" />
          <path d="M391.107264,231.487916 C390.907322,230.288376 390.987802,229.059371 391.34116,227.891754 C391.34116,227.891754 391.79889,223.72713 393.566938,223.134114 C395.179056,222.593891 397.653819,223.824125 399.263422,224.371714 C400.440443,224.773197 404.219236,224.958592 404.219236,224.958592 C407.198258,225.309736 409.320922,227.953143 408.960019,230.861749 L408.819178,231.990077 L406.106747,233.894361 L405.135955,236 L404.941043,235.976672 C403.995401,235.866172 403.31132,235.053383 403.365393,234.138688 C403.454675,232.628521 402.550532,231.247271 401.011349,231.064332 L396.61135,230.543754 C393.932871,230.22576 392.367281,231.465816 392.124583,233.420438 L392.055421,235.091444 L391.107264,231.487916" id="Fill-127" fill="#1A4D79" />
          <path d="M421.923438,256.494555 C418.088048,281.422487 392.988676,309.366732 349.709224,306.840439 C348.709799,306.781321 347.945533,305.901126 348.003044,304.873793 C348.052888,303.966009 348.730247,303.248715 349.573752,303.133107 C378.876589,299.086834 403.647506,284.298231 409.434459,254.646143 C410.270295,250.360772 413.718439,248.465067 416.939093,249.129812 C420.26199,249.815576 422.443599,253.084124 421.923438,256.494555" id="Fill-128" fill="#F1B4A7" />
          <path d="M404.010833,267.026573 C406.984866,260.700978 407.978846,256.763072 408.005212,256.66507 C408.005212,256.66507 408.286005,255.767778 408.362465,255.462316 C408.98601,252.974079 410.387338,251.465864 410.387338,251.465864 C410.387338,251.465864 413.222952,248.510526 418.658208,250.992399 C422.999294,252.972807 421.866894,257.982372 421.866894,257.982372 C421.727157,258.679842 422.278196,264.031779 415.4047,274 C415.4047,274 403.592939,267.913683 404.010833,267.026573" id="Fill-129" fill="#62C0C6" />
          <path d="M344.327098,301.497832 C344.327098,301.497832 349.955802,301.251334 350.601607,302.937546 C351.246053,304.623759 351.368416,305.873859 348.940188,306.901839 C346.511959,307.928465 342.687432,308.184444 340.363892,307.879707 C338.038992,307.577678 334.3613,305.430974 334.120653,304.940686 C333.240998,303.162375 337.391827,304.151078 339.425774,304.534369 C341.459721,304.916307 343.794138,305.146552 343.703046,304.118572 C343.609234,303.091946 339.659624,302.653125 339.164733,301.510022 C338.667123,300.369627 344.327098,301.497832 344.327098,301.497832" id="Fill-130" fill="#F1B4A7" />
          <path d="M328.264662,267.806444 C328.264662,267.806444 333.465234,267.207574 333.832289,265.331596 C334.199343,263.454176 334.143844,262.117903 331.776278,261.405031 C329.408712,260.69216 325.864301,261.006747 323.770449,261.680656 C322.182402,262.192942 320.019176,263.944816 319,264.99825 L324.319139,264.99825 C326.501286,264.999693 327.29594,264.230542 327.340088,265.138226 C327.393065,266.234952 323.824688,267.29993 323.521962,268.579923 C323.219237,269.859917 328.264662,267.806444 328.264662,267.806444" id="Fill-131" fill="#EA8F84" />
        </g>
      </g>
    </g>
  </svg>
);

export default WebGraphic;
