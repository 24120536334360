import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useDispatch, useSelector } from 'react-redux';
import SectionStyled from '../../../shared/SectionStyled';
import AutoCol from '../../../shared/AutoCol';
import MainText from '../../../shared/MainText';
import ButtonStyled from '../../../shared/ButtonStyled';
import WebGraphic from './WebGraphic';
import ResponsiveIcon from './ResponsiveIcon';
import Title from '../../../shared/Title';
import Text from '../../../shared/Text';
import IconWrapper from '../../../shared/IconWrapper';
import CloudBasedIcon from './CloudBasedIcon';
import RapidIcon from './RapidIcon';
import SecureIcon from './SecureIcon';
import { setEstimateData, setIsEstimateMenuOpen } from '../../../../redux/actions';
import WebLocales from './WebLocales.json';

const Web = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const locales = WebLocales[state.lang];

  const onButtonClick = () => {
    dispatch(setIsEstimateMenuOpen(true));
    dispatch(setEstimateData({ value: 'web', label: 'Responsive web application' }));
  };

  return (
    <SectionStyled
      id="web"
      background="white"
    >
      <Grid>
        <Row center="xs" start="md">
          <AutoCol md={6} xs={11}>
            <MainText marginTop color="black">
              {locales.main}
            </MainText>
            <ButtonStyled onClick={onButtonClick}>
              {locales.button}
            </ButtonStyled>
          </AutoCol>
          <AutoCol xs={11} md={6}>
            <WebGraphic />
          </AutoCol>
        </Row>
        <Row style={{ marginTop: 50 }} center="xs" start="xs">
          {[
            {
              icon: <ResponsiveIcon />,
              iconWidth: 89,
              iconHeight: 77,
              title: locales.title1,
              text: locales.text1,
            },
            {
              icon: <CloudBasedIcon />,
              iconWidth: 77,
              iconHeight: 77,
              title: locales.title2,
              text: locales.text2,
            },
            {
              icon: <RapidIcon />,
              iconWidth: 84,
              iconHeight: 80,
              title: locales.title3,
              text: locales.text3,
            },
            {
              icon: <SecureIcon />,
              iconWidth: 59,
              iconHeight: 73,
              title: locales.title4,
              text: locales.text4,
            },
          ].map(section => (
            <Col key={section.title} md={3} xs={5}>
              <IconWrapper
                key={section.title}
                width={section.iconWidth}
                height={section.iconHeight}
              >
                {section.icon}
              </IconWrapper>
              <Title>{section.title}</Title>
              <Text>{section.text}</Text>
            </Col>
          ))}
        </Row>
      </Grid>
    </SectionStyled>
  );
};

export default Web;
