import React from 'react';

const SecureIcon = () => (
  <svg viewBox="0 0 59 73" version="1.1">
    <g id="Offer" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(-1133.000000, -625.000000)" fill="#000000" fillRule="nonzero">
        <g id="Group-5" transform="translate(1047.000000, 625.000000)">
          <g id="shield" transform="translate(86.000000, 0.000000)">
            <path d="M29,6 C27.8912913,6 27,6.89295696 27,8.0001435 C27,9.03064787 27.7751752,9.88816685 28.7997998,9.98987585 C29.985986,10.107602 31,9.1836118 31,8.0001435 C31,6.89135524 30.1071071,6 29,6 Z" id="Path" />
            <path d="M57.5952381,8.5546875 C53.7223095,8.5546875 50.5714286,5.35666016 50.5714286,1.42578125 C50.5714286,0.638464844 49.9423762,0 49.1666667,0 L9.83333333,0 C9.05762381,0 8.42857143,0.638464844 8.42857143,1.42578125 C8.42857143,5.35666016 5.27769048,8.5546875 1.4047619,8.5546875 C0.629052381,8.5546875 0,9.19315234 0,9.98046875 L0,33.247793 C0,51.3804512 11.6447738,67.6283691 29.0965524,72.9399746 C29.2281786,72.9798965 29.364019,73 29.5,73 C29.635981,73 29.7721024,72.9798965 29.9035881,72.939832 C46.9773452,67.7415762 59,51.7771035 59,33.247793 L59,9.98046875 C59,9.19315234 58.3709476,8.5546875 57.5952381,8.5546875 Z M56.1904762,33.247793 C56.1904762,50.2784648 45.4832405,65.0273164 29.5,70.0832793 C13.6441714,65.067666 2.80952381,50.1852187 2.80952381,33.247793 L2.80952381,11.3047344 C7.10964048,10.6776758 10.5202619,7.21602148 11.1380762,2.8515625 L47.8619238,2.8515625 C48.4797381,7.21602148 51.8903595,10.6776758 56.1904762,11.3047344 L56.1904762,33.247793 Z" id="Shape" />
            <path d="M53.1370235,13.3253956 C50.1315941,12.050813 47.7514912,9.74601388 46.4352647,6.83567287 C46.2055412,6.32796113 45.6875824,6 45.1151471,6 L35.2618235,6 C34.4660059,6 33.8206471,6.6249404 33.8206471,7.39557927 C33.8206471,8.16621814 34.4660059,8.79115854 35.2618235,8.79115854 L44.2038912,8.79115854 C45.7264941,11.677356 48.13715,14.0116019 51.1176471,15.486171 L51.1176471,32.9611959 C51.1176471,46.6187533 42.6508794,58.4030247 29.5,63.1202222 C16.3491206,58.4028851 7.88235294,46.6187533 7.88235294,32.9611959 L7.88235294,15.486171 C10.86285,14.0116019 13.2733618,11.677356 14.7961088,8.79115854 L23.7381765,8.79115854 C24.5339941,8.79115854 25.1793529,8.16621814 25.1793529,7.39557927 C25.1793529,6.6249404 24.5339941,6 23.7381765,6 L13.8848529,6 C13.3124176,6 12.7944588,6.32810069 12.5647353,6.83567287 C11.2485088,9.74601388 8.86840588,12.050813 5.86297647,13.3253956 C5.33882059,13.5478509 5,14.0495617 5,14.6037462 L5,32.9611959 C5,47.9236199 14.5565853,60.9639126 29.0192235,65.9200333 C29.1747265,65.9733444 29.3372912,66 29.5,66 C29.6627088,66 29.8252735,65.9733444 29.9807765,65.9200333 C44.4313088,60.9680994 54,47.9357615 54,32.9611959 L54,14.6037462 C54,14.0494221 53.6611794,13.5478509 53.1370235,13.3253956 Z" id="Path" />
            <path d="M29,20 C21.8318,20 16,25.8318 16,33 C16,40.1682 21.8318,46 29,46 C36.1682,46 42,40.1682 42,33 C42,25.8318 36.1682,20 29,20 Z M29,43.1111111 C23.4247333,43.1111111 18.8888889,38.5752667 18.8888889,33 C18.8888889,27.4247333 23.4247333,22.8888889 29,22.8888889 C34.5752667,22.8888889 39.1111111,27.4247333 39.1111111,33 C39.1111111,38.5752667 34.5752667,43.1111111 29,43.1111111 Z" id="Shape" />
            <path d="M34.6420437,30.4182857 C34.1646463,29.8605714 33.3909839,29.8605714 32.9134644,30.4182857 L27.666738,36.551 L26.0865356,34.7041429 C25.6091383,34.1464286 24.8354759,34.1464286 24.3579563,34.7041429 C23.8806812,35.262 23.8806812,36.1665714 24.3579563,36.7245714 L26.8023872,39.5817143 C27.0412081,39.8604286 27.3538508,40 27.666738,40 C27.9796251,40 28.2922678,39.8604286 28.5309665,39.5815714 L34.6420437,32.4387143 C35.1193188,31.8808571 35.1193188,30.9762857 34.6420437,30.4182857 Z" id="Path" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SecureIcon;
