import React from 'react';
import { useDispatch } from 'react-redux';
import Offer from '../components/offer/Offer';
import App from '../components/shared/App';
import { setIsOfferOpen } from '../redux/actions';

export default ({ location }) => {
  const dispatch = useDispatch();

  dispatch(setIsOfferOpen(true));

  return (
    <App location={location}>
      <Offer />
    </App>
  );
};
