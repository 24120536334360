import styled from '@emotion/styled';

const Title = styled.p`
color: ${props => props.color};
font-size: 24px;
font-weight: bold;
text-align: center;
margin-top: 15px;
margin-bottom: 15px;

`;

export default Title;
