import React from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { useDispatch, useSelector } from 'react-redux';
import SectionStyled from '../../../shared/SectionStyled';
import MainText from '../../../shared/MainText';
import ButtonStyled from '../../../shared/ButtonStyled';
import AutoCol from '../../../shared/AutoCol';
import MobileGraphic from './MobileGraphic';
import RapidIcon from './RapidIcon';
import IconWrapper from '../../../shared/IconWrapper';
import Title from '../../../shared/Title';
import Text from '../../../shared/Text';
import NativeIcon from './NativeIcon';
import CrossPlatform from './CrossPlatform';
import ShareableIcon from './ShareableIcon';
import RouterLink from '../../../footer/RouterLink';
import MobileLocales from './MobileLocales.json';
import { setEstimateData, setIsEstimateMenuOpen } from '../../../../redux/actions';

const Mobile = () => {
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const locales = MobileLocales[state.lang];

  const onButtonClick = () => {
    dispatch(setIsEstimateMenuOpen(true));
    dispatch(setEstimateData({ value: 'mobile', label: 'Lighting fast mobile application' }));
  };

  return (
    <SectionStyled
      id="mobile"
      background="black"
    >
      <Grid>
        <Row center="xs">
          <AutoCol md={6} xs={11}>
            <MobileGraphic />
          </AutoCol>
          <AutoCol md={6} xs={10}>
            <MainText toRight color="white">
              {locales.main}
            </MainText>
            <RouterLink to="/offer">
              <ButtonStyled onClick={onButtonClick} toRight color="white">{locales.button}</ButtonStyled>
            </RouterLink>
          </AutoCol>
        </Row>
        <Row style={{ marginTop: 50 }} center="xs" start="xs">
          {[
            {
              icon: <NativeIcon />,
              iconWidth: 76,
              iconHeight: 74,
              title: locales.title1,
              text: locales.text1,
            },
            {
              icon: <CrossPlatform />,
              iconWidth: 77,
              iconHeight: 77,
              title: locales.title2,
              text: locales.text2,
            },
            {
              icon: <RapidIcon />,
              iconWidth: 84,
              iconHeight: 80,
              title: locales.title3,
              text: locales.text3,
            },
            {
              icon: <ShareableIcon />,
              iconWidth: 77,
              iconHeight: 67,
              title: locales.title4,
              text: locales.text4,
            },
          ].map(section => (
            <AutoCol key={section.title} md={3} xs={5}>
              <IconWrapper width={section.iconWidth} height={section.iconHeight}>
                {section.icon}
              </IconWrapper>
              <Title color="white">{section.title}</Title>
              <Text color="white">{section.text}</Text>
            </AutoCol>
          ))}

        </Row>
      </Grid>
    </SectionStyled>
  );
};

export default Mobile;
