import React from 'react';
import Web from './sections/0_web/Web';
import Mobile from './sections/1_mobile/Mobile';

const Offer = () => (
  <>
    <Web />
    <Mobile />
  </>
);

Offer.propTypes = {

};

export default Offer;
